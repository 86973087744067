<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="8"
        md="8"
      >
        <PublicBeritaTable></PublicBeritaTable>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <PublicAgendaTable></PublicAgendaTable>
      </v-col>
    </v-row>
    <AppFooter/>
  </div>
</template>

<script>
import AppFooter from "@/components/landing/AppFooter";
import PublicAgendaTable from "@/components/landing/PublicAgendaTable";
import PublicBeritaTable from "@/components/landing/PublicBeritaTable";
export default {
  components: {PublicBeritaTable, PublicAgendaTable, AppFooter},
  data () {
    return {
    }
  },


}
</script>

<style>
</style>
