<template>
  <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">
    <v-card-title>
      <v-row>
        <v-col
            cols="4"
            sm="4"
            md="4"
        >
          Agenda
        </v-col>
        <v-col
            cols="6"
            sm="6"
            md="6"
            class="ml-4"
        >
          <v-text-field
              v-on:keyup.enter="searchOnEnter"
              v-on:blur="searchOnEnter"
              append-icon="mdi-magnify"
              hint="Judul, Deskripsi Singkat (press ENTER to search)"
              label="Search"
          ></v-text-field>
        </v-col>
      </v-row>

    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="fNewsFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
        hide-default-header
    >
      <template v-slot:[`item.title`]="{ item }">
        <div class="fill-height">
          {{item.title}}... <router-link style="text-decoration: none" :to="`public-news-page/` + linkNeated(item.id.toString())">Selengkapnya</router-link>
        </div>
      </template>
      <template v-slot:[`item.contentMeta`]="{ item }">
        <div class="fill-height">
          {{item.contentMeta}}
        </div>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <div class="fill-height">
          <div>
            {{ dateTimeFormat(item.created ) }}
          </div>
          <div>
            by {{item.modifiedBy}}
          </div>
          <div>
            <div class="green--text" v-if="item.flagExposed">
              <v-icon color="green">
                mdi-checkbox-blank-circle
              </v-icon>
              Sedang Tayang
            </div>
            <div v-else>
              <v-icon color="grey" small>
                mdi-checkbox-blank-circle
              </v-icon>
            </div>
          </div>

        </div>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.coverImage`]="{item}">
        <v-img
          :lazy-src="lookupImageUrlLazy"
          :src="lookupImageUrl(item)"
          width="70px"
          height="70px"
          class="ma-2 rounded"
        >
        </v-img>
      </template>

    </v-data-table>

    <v-container v-if="false">
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>


    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FNewsService from '@/services/apiservices/f-news-service';
import FileService from "@/services/apiservices/file-service"

import FNews from '@/models/f-news'
import FDivision from '@/models/f-division'

import {format, parseISO} from "date-fns";

export default {
  components: {

  },
  data () {
    return {
      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 15,
      pageSizes: [15, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: '', value: 'title', width:'30%' },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fBeritas:[
          new FNews(1, "001")
      ],
      itemsFDivision: [
          new FDivision()
      ],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFNews()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFNews()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fNewsFiltered(){
      return this.fBeritas
    }
  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFNews()
      }
    },
    fetchParent(){
    },

    fetchFNews() {
      FNewsService.getAllFNewsContainingPublicAgenda(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fBeritas = items
            this.totalPaginationPages = totalPages

            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.statusCode === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },


    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.coverImage===undefined || item.title===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.coverImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {

    this.fetchFNews()
    this.fetchParent()

  }

}
</script>

<style scoped>

</style>