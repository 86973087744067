var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 mt-4 service bg-light section text-xs-center"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_vm._v(" Agenda ")]),_c('v-col',{staticClass:"ml-4",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hint":"Judul, Deskripsi Singkat (press ENTER to search)","label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOnEnter.apply(null, arguments)},"blur":_vm.searchOnEnter}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.fNewsFiltered,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"hide-default-footer":"","align":"start","hide-default-header":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalTablePages =_vm.totalPaginationPages}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_vm._v(" "+_vm._s(item.title)+"... "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"public-news-page/" + _vm.linkNeated(item.id.toString())}},[_vm._v("Selengkapnya")])],1)]}},{key:"item.contentMeta",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_vm._v(" "+_vm._s(item.contentMeta)+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',[_vm._v(" "+_vm._s(_vm.dateTimeFormat(item.created ))+" ")]),_c('div',[_vm._v(" by "+_vm._s(item.modifiedBy)+" ")]),_c('div',[(item.flagExposed)?_c('div',{staticClass:"green--text"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-checkbox-blank-circle ")]),_vm._v(" Sedang Tayang ")],1):_c('div',[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v(" mdi-checkbox-blank-circle ")])],1)])])]}},{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index + ((_vm.currentPage-1)*_vm.pageSize) +1 ))+" ")]}},{key:"item.coverImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2 rounded",attrs:{"lazy-src":_vm.lookupImageUrlLazy,"src":_vm.lookupImageUrl(item),"width":"70px","height":"70px"}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),(false)?_c('v-container',[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per page"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"10","md":"9","sm":"8","align":"right"}},[_c('v-pagination',{attrs:{"length":_vm.totalPaginationPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackBarMesage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }