<template>
  <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">

    <v-row class="mr-10 ml-10">
      <v-col
        cols="6"
        sm="6"
        md="6"
      >
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Judul, Deskripsi Singkat (press ENTER to search)"
            label="Search"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-layout wrap justify-center>
      <div  v-for="(item, i) in fNewsFiltered"
             :key="i">

            <v-hover>
              <template v-slot:default="{ hover }">

                <v-card
                    max-width="320"
                    xs12 sm4 px-3
                    class="mx-2 my-2 rounded-lg"
                    :elevation="hover? 16: 4"
                >

                  <v-img
                      height="250"
                      :lazy-src="lookupImageUrlLazy"
                      :src="lookupImageUrl(item)"
                  ></v-img>

                  <div class="font-weight-bold mt-2 mx-4 text-justify" v-html="item.title"></div>

                  <v-card-text>
                    <v-row
                        align="center"
                        class="mx-0"
                        v-if="false"
                    >
                      <v-rating
                          :value="item.rating"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                      ></v-rating>

                      <div class="grey--text ms-4">
                        {{ item.rating }} ({{item.viewer}})
                      </div>
                    </v-row>

                    <div class="my-1 font-weight-light text-justify" >
                      {{item.contentMeta}}
                    </div>

                    <!--          <div v-html="item.contentBody">... <router-link style="text-decoration: none" :to="`public-news-page/` + linkNeated(item.id.toString())">Selengkapnya</router-link></div>-->
                    <div class="text-justify" v-html="item.contentBody"> </div>
                    <router-link style="text-decoration: none" :to="`public-news-page/` + linkNeated(item.id.toString())">
                      <v-btn color="primary" small rounded class="mt-1">
                        Selengkapnya
                      </v-btn>
                    </router-link>

                  </v-card-text>

                </v-card>

              </template>
            </v-hover>


      </div>


    </v-layout>

    <v-container>
      <v-row justify="center" align="center">
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>


    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>

import FNewsService from '@/services/apiservices/f-news-service';
import FileService from "@/services/apiservices/file-service"

import FNews from '@/models/f-news'
import FDivision from '@/models/f-division'

import {format, parseISO} from "date-fns";

export default {
  metaInfo: {
    title: 'Smart Ejakon',
    titleTemplate: '%s | Berita'
  },
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9,
      pageSizes: [9, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: '', value: 'title', width:'30%' },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fBeritas:[
          new FNews(1, "001")
      ],
      itemsFDivision: [
          new FDivision()
      ],
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFNews()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFNews()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fNewsFiltered(){
        let beritasModified =[]
        for (let i=0; i<this.fBeritas.length; i++){
          let itemBerita = this.fBeritas[i];
          if (itemBerita.contentBody !==undefined){
            if (itemBerita.contentBody.length>100){
              itemBerita.contentBody = itemBerita.contentBody.substr(0, 99)
            }
          }
          beritasModified.push(itemBerita)
        }

        return beritasModified;

      // return this.fBeritas
    },


  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFNews()
      }
    },
    fetchParent(){
    },

    fetchFNews() {
      FNewsService.getAllFNewsContainingPublicBerita(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fBeritas = items
            this.totalPaginationPages = totalPages

            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.statusCode === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },


    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.coverImage===undefined || item.title===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.coverImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    document.title = "Smart Ejakon | Berita Agenda"

    this.fetchFNews()
    this.fetchParent()

  }

}
</script>

<style scoped>

</style>